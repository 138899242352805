<template>
  <!-- <b-tab v-for="(tab, index) of RFMTab" :key="tab" :title="tab"> -->
  <div>
    <b-row>
      <template v-for="(scale, n) of data">
        <b-col cols="4" md="3" lg="2" :key="scale.rate_scale + 'a-' + n">
          <InputText
            :textFloat="n == 0 ? 'Score' : ''"
            :name="'score' + n"
            placeholder=""
            type="number"
            :disabled="true"
            v-model="scale.rate_scale"
          />
        </b-col>
        <b-col cols="4" md="3" lg="2" :key="scale.rate_scale + 'b-' + n">
          <InputText
            :textFloat="n == 0 ? 'From' : ''"
            :name="'from' + n"
            placeholder=""
            type="number"
            :useDot="false"
            v-model="scale.st_range"
          />
        </b-col>
        <b-col cols="4" md="3" lg="2" :key="scale.rate_scale + 'c-' + n">
          <InputText
            :textFloat="n == 0 ? 'To' : ''"
            :name="'to' + n"
            placeholder=""
            type="number"
            :useDot="false"
            v-model="scale.ed_range"
          />
        </b-col>
        <div class="break-normal" :key="'break-row' + scale.id + n + '-'"></div>
      </template>
    </b-row>
    <hr />
    <b-button variant="custom-link" @click="handleCalPeriod()">
      Recalculate
    </b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$report("/Dashboard/RedemptionRate/rate_scale");
      this.data = res.data.detail;
    },
    async handleCalPeriod() {
      //   this.isRFMLoading = false;
      this.$bus.$emit("showLoading");
      const resp = await this.$report(
        `/Dashboard/RedemptionRate/calculate_rate_scale`
      );
      this.$bus.$emit("hideLoading");
      if (!resp.data.detail) {
        return this.errorAlert("There is no data in the calculation.");
      }

      this.data = resp.data.detail;
    },
    async save() {
      this.$bus.$emit("showLoading");
      const resp = await this.$report.post(
        `/Dashboard/RedemptionRate/new_rate_scale`,
        this.data
      );
      this.$bus.$emit("hideLoading");
      if (resp.data.result == 0) {
        return this.errorAlert("There is no data in the calculation.");
      }

      // this.data = resp.data.detail;
    },
  },
};
</script>

<style></style>
